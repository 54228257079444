import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#fff',
      [theme.breakpoints.down(768)]: {
        padding: '60px 0',
      },
      [theme.breakpoints.down(500)]: {
        padding: '40px 21px',
      },
    },
    cardContainer: {
      '& .CarouselItem': {
        minHeight: '380px',
      },
      maxWidth: '550px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '450px',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '350px',
        '& .CarouselItem': {
          minHeight: '300px',
        },
      },
    },
  })
);
export default useStyles;
