import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootCard: {
      width: 'auto',
      paddingTop: '50px',
      color: '#074F71',
      [theme.breakpoints.down('md')]: {
        margin: '0 auto',

        paddingTop: '0px',
      },
    },
    card: {
      height: 350,
      [theme.breakpoints.up(501)]: {
        maxHeight: 500,
      },
    },
    img: {
      maxHeight: 250,
      [theme.breakpoints.up(501)]: {
        maxHeight: 300,
      },
    },
    logoSJM: {
      paddingBottom: 15,
      height: 100,
      paddingInlineStart: '20%',
      [theme.breakpoints.up(501)]: {
        height: 150,
      },
      '& .MuiCardMedia-media': {
        width: '70%',
        height: 'auto',
        position: 'relative',
        top: '-30px',
        [theme.breakpoints.up(501)]: {
          top: '-10px',
        },
      },
    },
    logo: {
      paddingBottom: 15,
      height: 100,
      paddingInlineStart: '20%',
      '& .MuiCardMedia-media': {
        width: '70%',
        height: 'auto',
      },
    },
    personContainer: {
      marginBottom: '28px',
    },
    title: {
      fontSize: '28px',
      color: '#074F71',
      lineHeight: '32px',
      marginBottom: `6px`,
    },
  })
);
export default useStyles;
