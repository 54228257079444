import React from 'react';
import { Card, CardContent, Grid, CardMedia } from '@material-ui/core';
import useStyles from './FoundationsCard.style';

export interface IFoundationData {
  logo: string;
  image: string;
  name: string;
}

const FoundationCard = (props: IFoundationData) => {
  const { image, logo, name } = props;
  const classes = useStyles();
  return (
    <div className={classes.rootCard}>
      <Card className={classes.card}>
        <Grid
          className={name === 'Servicio Jesuita a Migrantes (SJM)' ? classes.logoSJM : classes.logo}
        >
          <CardMedia component="img" image={logo} title="foundation logo" />
        </Grid>
        <Grid className={classes.img}>
          <CardMedia component="img" image={image} title="foundation" />
        </Grid>
        <CardContent></CardContent>
      </Card>
    </div>
  );
};

export default FoundationCard;
