import PorLaNiñez from '../../../../../images/Foundations/PorLaNiñez.jpg';
import SJM from '../../../../../images/Foundations/SJM.jpg';
import miParque from '../../../../../images/Foundations/miParque.png';
import PorLaNiñezLogo from '../../../../../images/Foundations/porLaNiñezLogo.png';
import miParqueLogo from '../../../../../images/Foundations/miParqueLogo.png';
import SJMLogo from '../../../../../images/Foundations/sjmLogo.png';

const testimonials: any[] = [
  {
    name: 'Fundación Mi Parque',
    image: miParque,
    logo: miParqueLogo,
  },
  {
    name: 'Corporación por la niñez',
    image: PorLaNiñez,
    logo: PorLaNiñezLogo,
  },
  {
    name: 'Servicio Jesuita a Migrantes (SJM)',
    image: SJM,
    logo: SJMLogo,
  },
];

export default testimonials;
