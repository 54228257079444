import * as React from 'react';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import modalStyles from './TermsModal.style';
import CloseIcon from '@material-ui/icons/Close';

const TermsModal = ({ showModalComponent }) => {
  const handleClose = () => {
    showModalComponent(false);
  };
  const classes = modalStyles();
  return (
    <React.Fragment>
      <div>
        <div>
          <Dialog open={true} onClose={handleClose}>
            <DialogContent className={classes.root}>
              <div className={classes.goBack} onClick={handleClose}>
                <CloseIcon onClick={handleClose}></CloseIcon>
              </div>
              <div className={classes.terms}>
                <DialogContentText variant="h6" component="h2">
                  Términos y condiciones
                </DialogContentText>
                <DialogContentText>
                  <div className={classes.subtitle}>
                    <strong>I. Objetivo del Programa</strong>
                  </div>
                  El programa de referidos de CurrencyBird tiene como objetivo apoyar a distintas
                  organizaciones civiles que promueven el bienestar de la sociedad desde distintas
                  veredas, a través de un programa de donaciones.
                  <div className={classes.subtitle}>
                    <strong>II. Condiciones del Programa</strong>
                  </div>
                  Este programa sólo se activará una vez que se cumplan los siguientes requisitos
                  copulativos: Que un cliente de CurrencyBird (Referente) haya referido la
                  plataforma web de esta última a un tercero no cliente de CurrencyBird (Referido),
                  compartiendo un link que contendrá un código único generado en la sección "Invita
                  a un amig@" de la página web currencybird.cl; Que el Referido se registre como
                  cliente de CurrencyBird a través del link señalado en el punto anterior,
                  completando exitosamente su perfil en la plataforma; Que el Referido haya
                  realizado al menos una transferencia de dinero al exterior a través de la
                  plataforma de CurrencyBird. Por cada Referido que se registre y transfiera a lo
                  menos una vez, CurrencyBird dentro del marco de la Ley N* 21.440, donará $8.000
                  pesos chilenos por el Referente y $8.000 pesos chilenos por el Referido a alguna
                  de las fundaciones listadas en el sitio web, a elección de cada uno de ellos.
                  <div className={classes.subtitle}>
                    <strong>III. Operación del Programa</strong>
                  </div>
                  Para poder realizar la donación: El cliente debe ingresar a su sesión, ir al menú
                  "Invita a un amig@" y generar su código firmando los términos y condiciones del
                  programa. Ya activado su código, el cliente podrá copiar el link y compartirlo con
                  terceros con la intención de invitarlos a registrarse en CurrencyBird y realizar
                  una transferencia internacional. Una vez que el Referido se registra con nosotros
                  a través del link que recibió de parte del Referente y haya realizado su primera
                  transferencia, CurrencyBird donará $8.000 pesos chilenos por el Referente y $8.000
                  pesos chilenos por el Referido a alguna de las fundaciones adheridas al programa,
                  a elección independiente de cada uno de ellos. Para poder cobrar el premio, el
                  cliente debe ir al menú "Invita a un amig@", luego a la pestaña "Mis premios",
                  seleccionar el premio que está disponible y luego la fundación a quien quiere
                  realizar la donación. Los participantes del programa de referidos elegirán a qué
                  fundación CurrencyBird deberá donar $8.000 pesos chilenos sólo una vez por cada
                  referido que hayan invitado y siempre y cuando se cumplan todas las condiciones
                  del programa. Dicha elección deberá ser ejercida dentro del plazo de 6 meses.
                  Vencido este plazo, quedará automáticamente sin efecto la oportunidad de llevar a
                  cabo la donación. Debido a que las donaciones se acogen a la Ley N* 21.440, es
                  Currency Bird SpA quien realiza la donación, sin embargo, se entregará a cada
                  donante un certificado simbólico emitido por la fundación correspondiente.
                  CurrencyBird se reserva el derecho de verificar la identidad del Referente y la
                  del Referido, o bien de realizar validaciones adicionales, antes de realizar la
                  donación correspondiente. En caso de que CurrencyBird detecte que ha existido un
                  abuso en el uso de cualquiera de las cuentas, se reserva el derecho de cancelar el
                  programa de referidos para ambos. Si alguno de los conocidos del Referente se
                  registra en CurrencyBird de una manera distinta a la descrita anteriormente en
                  estos términos y condiciones, no se activará el programa de referidos descrito.
                  <div className={classes.subtitle}>
                    <strong>IV. Responsabilidad</strong>
                  </div>
                  Independientemente de la opción que el Referente elija para invitar a un conocido,
                  este entiende y acepta que es de su responsabilidad invitar única y exclusivamente
                  a personas a quienes conozca y que no se molesten por la recepción de la
                  invitación a registrarse en CurrencyBird. Del mismo modo, cualquier violación a la
                  privacidad humana o a la Ley del Consumidor, será de responsabilidad exclusiva del
                  Referente. CurrencyBird sólo hace entrega del link para que el Referente pueda
                  contactar a sus conocidos En caso de que el Referente decida enviar la invitación
                  al programa de referidos por medio de un SMS u otro medio que lleve asociado un
                  costo monetario, CurrencyBird no será responsable de este ni se lo reembolsará.
                  Cualquier costo monetario en que el Referente incurra para efectos de invitar a
                  algún contacto, será exclusivamente de su cargo.
                </DialogContentText>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </React.Fragment>
  );
};
export default TermsModal;
