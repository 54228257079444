import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      '& #BenefitsLink': {
        color: '#074F71',
      },
    },
    root: {
      background: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      color: '#074F71',
      marginTop: 72,
      paddingTop: 83,
      paddingBottom: 100,
      [theme.breakpoints.down(501)]: {
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
      [theme.breakpoints.down(769)]: {
        marginTop: 0,
        paddingTop: 100,
        paddingBottom: 0,
      },
    },
    referralsButton: {
      display: 'flex',
      justifyContent: 'center',
      background: '#F9B234',
      border: 0,
      textAlign: 'center',
      textTransform: 'inherit',
      color: '#FFFFFF',
      position: 'relative',
      borderRadius: '40px',
      fontSize: 22,
      marginTop: 10,
      width: 329,
      height: 50,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      '&:hover': {
        background: '#074F71',
        color: '#FAB234',
        boxShadow: '0px 2px 10px #888888',
      },
      [theme.breakpoints.down(769)]: {
        width: `100%`,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 18,
        fontWeight: 800,
      },
    },
    referralsButtonDiv: {
      paddingBottom: 50,
      [theme.breakpoints.down(500)]: {
        paddingBottom: 0,
      },
      [theme.breakpoints.down(769)]: {
        paddingBottom: 0,
      },
    },
    subtitle: {
      fontSize: 22,
      paddingBottom: 20,
      [theme.breakpoints.down(769)]: {
        paddingBottom: 30,
        textAlign: 'center',
        lineBreak: 'auto',
        fontSize: 12,
      },
    },
    imgTitle: {
      textAlign: 'center',
      [theme.breakpoints.up(501)]: {
        fontSize: 30,
      },
    },
    foundationsTitle: {
      fontSize: 36,
      paddingTop: 50,
      textAlign: 'center',
      color: '#36ADAC',
    },
    foundationsImg: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 80,
    },
    foundationsSection: {
      backgroundColor: '#DAF0EF',
      padding: '0 6%',
      [theme.breakpoints.up(1921)]: {
        padding: 0,
      },
    },
    foundations: {
      [theme.breakpoints.up(501)]: {
        paddingTop: 20,
      },
    },
    benefitsTitle: {
      fontSize: 18,
    },
    howWorksTitle: {
      fontSize: 30,
      paddingTop: 40,
      [theme.breakpoints.down(501)]: {
        paddingTop: 0,
        textAlign: 'center',
      },
      [theme.breakpoints.down(769)]: {
        paddingTop: 0,
        textAlign: 'center',
      },
    },
    howWorksTerms: {
      fontSize: 18,
      color: '#36ADAC',
      fontWeight: 300,
      paddingBottom: 20,
      textAlign: 'center',
      paddingLeft: 180,
      [theme.breakpoints.down(769)]: {
        paddingLeft: 0,
      },
    },
    howWorksList: {
      fontSize: 18,
      fontWeight: 300,
    },
    howWorksLink: {
      color: '#074F71',
    },
    howWorksLinkInvite: {
      color: '#F9B234',
    },
    howWorksRoot: {
      background: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      color: '#074F71',
    },
    howWorksButtonDiv: {
      paddingLeft: 200,
      paddingTop: 40,
      [theme.breakpoints.down(501)]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.down(769)]: {
        paddingLeft: 0,
      },
    },
    howWorksTermsButton: {
      fontSize: 16,
      color: '#074F71',
      fontWeight: 300,
      paddingLeft: 180,
      textAlign: 'center',
      background: 'none',
      fontFamily: 'Montserrat',
      border: 'none',
      [theme.breakpoints.down(769)]: {
        paddingLeft: 0,
      },
    },
    termsDiv: {
      [theme.breakpoints.down(769)]: {
        textAlign: 'center',
      },
    },
    howWorksTermsButtonUnderline: {
      fontSize: 15,
      color: '#074F71',
      fontWeight: 'bold',
      textAlign: 'center',
      textDecoration: 'underline',
    },
    howWorksButton: {
      background: '#36ADAC',
      display: 'flex',
      justifyContent: 'center',
      border: 0,
      textAlign: 'center',
      textTransform: 'inherit',
      color: '#FFFFFF',
      position: 'relative',
      borderRadius: '40px',
      fontSize: 22,
      marginTop: 10,
      width: 329,
      height: 50,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      '&:hover': {
        background: '#074F71',
        color: '#FAB234',
        boxShadow: '0px 2px 10px #888888',
      },
      [theme.breakpoints.down(769)]: {
        width: `100%`,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 18,
        fontWeight: 800,
      },
    },
    title: {
      fontSize: '48px',
      fontWeight: 300,
      '& span': {
        fontWeight: 500,
      },
      marginBottom: '26px',
      paddingBottom: 20,
      lineHeight: '58px',
      margin: 0,
    },
    firstSubSection: {
      '& h1': {
        margin: 0,
        [theme.breakpoints.down(769)]: {
          paddingTop: 30,
        },
      },
      '& h2': {
        margin: 0,
        marginBottom: 20,
        fontWeight: 300,
        lineHeight: `30px`,
        fontSize: '22px',
        paddingRight: '10%',
      },
    },
    firstSubSectionTitle: {
      marginBottom: 25,
    },
    container: {
      padding: '0 6%',
      [theme.breakpoints.up(1921)]: {
        padding: 0,
      },
    },
    referralsImage: {
      position: 'absolute',
      paddingTop: 50,
      left: '48%',
    },
    howWorks: {
      textDecoration: 'underline',
      fontSize: '22px',
      lineHeight: '30px',
      fontWeight: 600,
      color: '#F8B133',
    },
    [theme.breakpoints.down(1364)]: {
      title: {
        fontSize: '44px',
      },
      firstSubSection: {
        marginBottom: '18px',
        '& h1': {
          marginBottom: '18px',
        },
        '& h2': {
          fontSize: '20px',
        },
      },
    },
    [theme.breakpoints.down(769)]: {
      title: {
        fontSize: '50px',
        textAlign: 'center',
      },
      firstSubSectionTitle: {
        maxWidth: '100%',
        marginBottom: 25,
        paddingTop: 0,
      },
      firstSubSection: {
        marginBottom: '30px',
        '& h1': {
          marginBottom: '24px',
        },
        '& h2': {
          padding: 0,

          marginBottom: 0,
          textAlign: 'center',
        },
      },
    },
    [theme.breakpoints.down(501)]: {
      title: {
        fontSize: '30px',
        fontWeight: 300,
        marginBottom: '22px',
        lineHeight: '32px',
        padding: '0 10px',
      },
      firstSubSection: {
        '& h1': {
          fontSize: '30px',
        },
        '& h2': {
          textAlign: 'center',
          fontSize: '14px',
          lineHeight: '19px',
          padding: '0 5%',
          '& span': {
            fontWeight: 700,
          },
        },
      },
    },
  })
);

export default useStyles;
