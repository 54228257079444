import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const modalStyles = makeStyles((theme: Theme) =>
  createStyles({
    goBack: {
      width: '100%',
      padding: 10,
    },
    subtitle: {
      paddingBottom: 15,
      paddingTop: 10,
    },
    terms: {
      padding: 20,
      overflowY: 'scroll',
      height: 'calc(100% - 160px)',
      '& .MuiDialogContentText-root': {
        color: '#074F71',
      },
    },
    root: {
      position: 'fixed',
      top: '9%',
      color: '#074F71',
      right: 0,
      height: '100%',
      width: '50%',
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.down(769)]: {
        width: `100%`,
      },
    },
  })
);

export default modalStyles;
