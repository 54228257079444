import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Benefits from '../../components/Landing/Benefits/referrals-program/Referrals-program';
import SEO from '../../components/SEO';

const PAGE_TITLE = 'Programa Referidos';
const PAGE_DESCRIPTION =
  'Sé un motor de cambio con CurrencyBird. Participa en nuestro programa de referidos y apoya entidades sociales.';

const ReferralsPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} />
      <Benefits />
    </>
  );
};

export default ReferralsPage;

export const pageQuery = graphql`
  query ReferralsPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
