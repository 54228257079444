import React from 'react';
import useStyles from './Foundations.style';
import Carousel from 'react-material-ui-carousel';
import { Container, Grid, Hidden } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import foundations from './Foundation.data';
import FoundationsCard from './FoundationsCard';

const Foundations = () => {
  const theme = useTheme();
  const rows = foundations.reduce(function (rows, key, index) {
    return (index % 2 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows;
  }, []);

  const classes = useStyles();
  return (
    <section id="fundaciones" className={classes.root}>
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <div className={classes.cardContainer}>
            <Grid item xs={12}>
              <Carousel
                indicators={true}
                navButtonsAlwaysInvisible={true}
                interval={10000}
                // eslint-disable-next-line no-use-before-define
                indicatorIconButtonProps={{
                  className: 'base-color',
                  style: {
                    color: 'grey', // 3
                  },
                }}
                activeIndicatorIconButtonProps={{
                  className: 'active-color',
                  style: {
                    color: '#434343', // 2
                  },
                }}
              >
                {foundations.map((item, i) => (
                  <FoundationsCard key={i} image={item.image} logo={item.logo} name={item.name} />
                ))}
              </Carousel>
            </Grid>
          </div>
        </Grid>
      </Container>
    </section>
  );
};

export default Foundations;
